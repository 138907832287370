@import 'vars';

body {
    font-family: $font;
    font-size: 18px;
    color: $text;
    line-height: 1.2;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}
hr {
    position: relative;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $tertiary;
    margin: 1em 0;
    padding: 0;
}

h1, h2, h3, h4 {
    font-weight: 600;
}
/* Aiming to discourage the use of h1 */
h2, h3, h4 {
    color: $primary;
}

/* text styles */
.underline {
    position: relative;
    padding-bottom: 10px;
    &:after {
        content:"";
        position: absolute;
        bottom: 0px;
        left: 50%;
        display: block;
        width: 62px;
        margin-left: -31px;
        height: 1px;
        background-color: $tertiary;
    }
}
.excerpt {
    color: $tertiary;
    font-size: 25px;
    font-weight: 600;
    line-height: 1.4;
}
.blue {
    color: $primary;
}
.green {
    color: $secondary;
}

.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    padding: 10px 20px;
    min-width: 140px;
    max-width: 100%;
    color: $primary;
    background-color: transparent;
    border: 1px solid $primary;
    border-radius: 12px;
    box-sizing: border-box;
    margin: 5px;
    transition: $link-hover;
    &:hover {
        color: #FFF !important; /* overrule the page content a style */
        background-color: $primary;
    }
    &.rev {
        color: #FFF;
        border-color: #FFF;
        &:hover {
            color: $primary !important;
            background-color: #FFF;
        }
    }
}

/** images **/
img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

/** blocks **/
.col-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* ie */
    justify-content: space-evenly;
    width: 100%;
    box-sizing: border-box;
}
.three-col,
.four-col {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    padding: 1%;
    box-sizing: border-box;
}
.three-col {
    width: 30%;
}
.four-col {
    width: 24%;
}

.short-line {
    position: relative;
    text-align: center;
    &:before {
        content:"";
        display: inline-block;
        width: 48%;
        height: 1px;
        background-color: $tertiary;
        margin: 30px auto;
    }
}

@media only screen and (max-width: 960px){
    .four-col {
        width: 48%;
    }
}
@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
    .three-col {
        width: 48%;
    }
}
@media only screen and (max-width: 600px) {
    .three-col,
    .four-col {
        width: 100%;
        padding: 0px;
    }
}
@media only screen and (max-width: 300px){
    .button {
        min-width: 0px;
    }
    form button {
        width: 100%;
    }
}